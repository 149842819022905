export const realtorData = {
  email: 'maldonado.marlene8@gmail.com',
  phone:'56997916583'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'maldonado.marlene8@gmail.com',
  phone:'997916583',
  wsp:'997916583',
}
