const ZoneInMap = [
    {
        id:1,
        region:'Valparaiso',
        comuna:'Valparaiso',
        longitude:-71.61651342042937,
        latitude: -33.046482342483294,
    },
  ]


  export default ZoneInMap;